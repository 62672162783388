.campus-container{    
  width: 100%;
  height: 600px;
  overflow: hidden;
  perspective: 1500px;
}

.campus-gallery{
  position: absolute;
  top: 22.5%;
  left: 50%;
  transform: translateX(-50%) rotateX(55deg);
  transform-style: preserve-3d;
}

.campus-gallery-item{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 60px;
  margin: 10px;
  transform-style: preserve-3d;
  background: #b0b0b0;
}

img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.campus-preview-img{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 200px;
  z-index: 0;
}