.library-tabs > .react-tabs__tab-list{
  border-color: #2e2e88 !important;
}

.library-tabs > .react-tabs__tab-list > .react-tabs__tab{
  font-size: 1.6rem;
  font-weight: 500;
  color: #2e2e88 !important;
  margin-top: 0.5rem;
}

.library-tabs > .react-tabs__tab-list > .react-tabs__tab--selected{
  color: #2e2e88 !important;
  font-weight: 600;
  border-color: #2e2e88 !important;
}

/* PAGINATION STARTS */
.pagination{
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination-item{
  list-style: none;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1rem 1rem;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1rem;
  color: darkslategray;
}

.pagination-item:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-item:last-child{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-link{
  text-decoration: none;
  font-size: 1rem;
  color: darkslategray;
  transition: all 0.3s;
}

.pagination-item:hover{
  color: white;
  background-color: #1E56A0;
  font-weight: 600;
}

.pagination-item:hover .page-link{
  color: white;
}

.pagination-item-active{
  background-color: #1E56A0;  
}

.pagination-link-active{
  color: white !important;
  font-weight: 600;
}

/* PAGINATION ENDS */

.flex-gap{
  gap: 1.5rem;
}

@media screen and (max-width: 767px) {
  .flex-gap{
    gap: 0rem;
  }
  .pagination{
    flex-wrap: wrap;
  }
}

.social-icon:hover {
  opacity: 100% !important;
  color: white !important;
}

.input-container {
  position: relative;  
}

.input-field {
  width: 100%;
  padding: 1.5rem !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px !important;
  transition: border-color 0.2s ease-in-out;
  background-color: white !important;
  min-height: 5rem !important;
}

.input-field:disabled {
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
}

.input-field--focused {
  border-color: #2e3192 !important;
}

.input-label {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
  padding: 0 5px;
  font-size: 14px;
  color: #999;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.input-label--textarea {
  top: 25px !important;    
}

.input-label--focused {
  top: 0 !important;
  left: 0 !important;
  transform: translate(10px, -50%);
  font-size: 12px;
  color: #2e3192 !important;    
  background-color: white !important;
}

.break-word{
  word-break: break-word;
}

.text-justify{  
  text-align: justify !important;
}

#react-modal-image-img{
  object-fit: contain !important;
  width: fit-content !important;
}

.alpana-border {
  position: relative;  
  text-align: center;  
  background: white;
  border: 1px solid transparent;
}

.alpana-border::before,
.alpana-border::after,
.alpana-border > .left-right::before,
.alpana-border > .left-right::after {
  content: '';
  position: absolute;
  background: url('/public/images/alpana-border.png') center;
  background-size: contain;  
  z-index: 1;
}

.alpana-border::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 8px; 
  transform: rotate(180deg); /* Default facing downward for top */
}

.alpana-border::after {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px; 
  transform: rotate(0deg); /* Flip for bottom border */
}

.alpana-border > .left-right::before {
  top: 0;
  left: 0;
  width: 8px; /* Width of vertical border */
  height: 100%; /* Match parent height */
  transform: rotate(-180deg); /* Flip for left border */
}

.alpana-border > .left-right::after {
  top: 0;
  right: 0;
  width: 8px; /* Width of vertical border */
  height: 100%; /* Match parent height */
  transform: rotate(180deg); /* Flip for right border */
}