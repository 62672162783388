html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif !important;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;  
}
html {
  /* Set 1rem to the browser default (10px) */
  font-size: 62.5%;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.border-white {
  border-color: white !important;
}

/* form input css */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="date"],
input[type="time"],
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 3rem;
  font-size: 1.4rem;
  line-height: 1.8rem;  
  font-weight: 500;
  border-radius: 0.4rem;
  border: 1px solid #DEE1E6;
  margin-top: 1rem;  
  resize: vertical;
  outline: none;
  color: #141414;
  /* min-width: 16rem; */
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"], 
input[type="search"],
input[type="time"]{
  height: 4rem;
}

/* SAFARI FIX */
input[type="date"]{
  min-width:95% !important;  
}

.form-input-select{  
	height: 4rem;
  padding: 0.5rem 1rem;  
  border: 1px solid #DEE1E6;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.8rem;
  min-width: 16rem;
}

::-webkit-input-placeholder {
  color: #B3B3B3;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.8rem;  
}

.error {
  color: red;
  position: absolute;
  right: 0;
  font-size: 1rem;  
}

.input-error {
  border: 1px solid;
  border-color: red !important;
}

.syllabus-search-input{
  min-width: 20rem !important;
  max-width: 30rem !important;
  padding: 5px 10px !important;
  font-size: 1.4rem !important;
  line-height: 2rem !important;  
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid #454545;
  margin-top: 0.5rem !important;    
  height: auto !important;
  padding-left: 2.5rem !important;
}

.syllabus-search-input::-webkit-input-placeholder {
  color: #6c757d;
  font-weight: 400;
  font-size: 1.4rem !important;  
  font-family: "Lato";  
}

.active{
  color: #fd7e14 !important;
  font-weight: 600 !important;
}

.active > div{
  opacity: 1 !important;
}

.home-image-slider > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
  margin: 0 0.3rem !important;  
}

.home-image-slider > .slick-slider > .slick-list {
  margin: 0 -0.3rem !important;
}

.faculty-image-slider > .slick-slider > .slick-list > .slick-track > .slick-slide > div {
  margin: 0 0.3rem !important;  
}

.faculty-image-slider > .slick-slider > .slick-list {
  margin: 0 -0.3rem !important;
}

.list-image > li{
  list-style-image: url("https://ssju.ac.in/images/list.png");
}

::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: #002147;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}


.facultyTab-options{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 1rem;
  margin-bottom: 0px;
  background-color: #002147;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;    
}

.facultyTab-option{
  display: flex;    
  color: white;
  font-size: 15px;    
  cursor: pointer;
  text-decoration: none !important;
  list-style: none !important;
}

.facultyTab-option:hover{
  color: orange;        
}

.react-tabs__tab--selected{
  background-color: transparent !important;
  color: 16px !important;
  color: orange !important;  
  font-weight: 600 !important;      
}

.tab-body{
  padding: 2rem 2rem;
  box-shadow: 0 0 4px rgb(0 0 0 / 14%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.vocImageSlider{
  margin-bottom: 50px;
}

.vocImageSlider > div{
  max-height: 600px;        
}

.vocImageSlider > div > div > ul > li > div{
  border-radius: 4px;
}

.vocImageSlider > div > div > ul > li > div > img{    
  mask-image: linear-gradient(rgba(0, 0, 0, 1.0), transparent);
  padding: 0 5px;
  object-fit: contain; 
  border-radius: 8px;
}

.vocImageSlider > div > div > ul > .selected > div > img{    
  mask-image: none;
}

@media(max-width:768px){
  .facultyTab-option{        
      font-size: 11px;
  }
  .react-tabs__tab--selected{    
    color: 12px !important;    
  }  
}

@media(max-width:350px){
  .facultyTab-option{        
      font-size: 8px;
      padding: 2px;
  }
  .react-tabs__tab--selected{    
    color: 10px !important;    
  }
}

@media(max-width: 767px){
  .vocImageSlider > div{
      max-height: 250px;        
  }  
}

.sports-card-background-blur{  
  filter: blur(1.5px);
}

.float-left{
  float: left;
}

@media(max-width: 767px){
  .float-left{
    float: none;
  }
}